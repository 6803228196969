const services = [
    {
        title: "Social Media Marketing",
        description: "Boost your brand's presence on social media with our expert marketing strategies. We create engaging content and targeted campaigns to increase your reach and engagement.",
        link: "/services/marketing",
        slang: "Enhance your brand's voice on social media.",
        image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "In today's digital age, social media platforms have become powerful tools for brand visibility and customer engagement. Establishing a strong presence on social media not only increases brand awareness but also fosters direct communication with your audience.",
        how: "We achieve this by leveraging data-driven strategies tailored to your brand's unique voice and audience demographics. Our team creates compelling content and implements targeted campaigns to maximize engagement and drive results.",
        background: "Our team comprises social media experts with diverse backgrounds in digital marketing, communications, and analytics. With a deep understanding of social media trends and algorithms, we stay ahead by continuously adapting strategies to align with platform updates and user behaviors.",
        metaTitle: "Social Media Marketing Services - Boost Your Brand on Social Platforms",
        metaDescription: "Discover our Social Media Marketing services to boost your brand's presence on social platforms. We create engaging content and targeted campaigns tailored to increase your reach and engagement.",
        metaKeywords: "social media marketing, brand engagement, targeted campaigns, social media presence",
    },
    {
        title: "Software Development",
        description: "Our custom software solutions are tailored to meet the unique needs of your business. We build scalable and secure software that helps you achieve your business objectives efficiently.",
        link: "/services/development",
        slang: "Turn your ideas into powerful solutions.",
        image: "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "Custom software solutions are essential for businesses seeking to streamline operations and gain a competitive edge. Off-the-shelf software often falls short in addressing specific business requirements, making bespoke solutions the preferred choice.",
        how: "At Zimapeak Marketing, we transform your ideas into robust software solutions through agile development methodologies. From initial concept to deployment, our team ensures seamless integration and rigorous testing to deliver scalable and secure applications.",
        background: "Our development team consists of seasoned professionals proficient in various programming languages and frameworks. With extensive experience across industries such as healthcare, finance, and e-commerce, we have successfully delivered tailored solutions that drive efficiency and innovation.",
        metaTitle: "Custom Software Development Solutions - Tailored Business Solutions",
        metaDescription: "Explore our Custom Software Development solutions tailored to meet your business needs. We specialize in building scalable, secure software to help you achieve your objectives efficiently.",
        metaKeywords: "custom software development, business solutions, scalable software, secure solutions",
    },
    {
        title: "SEO",
        description: "Improve your website's visibility and ranking on search engines with our SEO services. We use the latest techniques to drive organic traffic and boost your online presence.",
        link: "/services/seo",
        slang: "Increase your online presence with SEO expertise.",
        image: "https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "SEO is crucial for businesses aiming to enhance online visibility and attract qualified traffic. With search engines serving as primary gateways to information, a high-ranking website not only drives organic traffic but also establishes credibility and trust among potential customers.",
        how: "Our SEO experts employ a comprehensive approach that includes keyword optimization, strategic link building, and thorough SEO audits. By analyzing market trends and algorithm updates, we tailor strategies to improve your website's search engine rankings and maximize ROI.",
        background: "With a dedicated team proficient in SEO best practices, we have successfully optimized websites across diverse industries. From local businesses to global enterprises, our track record includes achieving significant improvements in search engine rankings and organic traffic.",
        metaTitle: "SEO Services - Improve Website Visibility and Rankings",
        metaDescription: "Enhance your website's visibility and search engine rankings with our SEO services. We use advanced techniques for driving organic traffic and boosting your online presence.",
        metaKeywords: "SEO services, improve website visibility, organic traffic, search engine rankings",
    },
    {
        title: "Content Creation",
        description: "Create compelling content that resonates with your audience. Our content creation services include blog posts, videos, infographics, and more to engage and inform your customers.",
        link: "/services/content",
        slang: "Craft stories that captivate and inform.",
        image: "https://images.unsplash.com/photo-1555421689-491a97ff2040?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "Content serves as a vital tool for engaging and nurturing relationships with your audience. By delivering valuable and relevant information, businesses can establish authority in their industry, foster customer loyalty, and drive conversions.",
        how: "Our approach to content creation involves meticulous strategy development and multimedia content production. From compelling blog posts to visually captivating infographics and informative videos, we craft content that resonates with your target audience across various channels.",
        background: "Backed by a team of creative professionals and digital marketing experts, we have honed our expertise in storytelling and content distribution strategies. With a deep understanding of audience behaviors and content consumption trends, we consistently deliver impactful content that drives engagement and amplifies brand visibility.",
        metaTitle: "Content Creation Services - Engage Your Audience with Compelling Content",
        metaDescription: "Discover our Content Creation services that include blog posts, videos, and infographics to engage and inform your audience. Crafted stories that captivate and drive engagement.",
        metaKeywords: "content creation, engage audience, blog posts, infographics, storytelling",
    },
    {
        title: "CRM Systems",
        description: "Implement CRM systems that streamline your customer management processes, improve customer relationships, and enhance your business efficiency.",
        link: "/services/crm",
        slang: "Build strong customer relationships with CRM solutions.",
        image: "https://images.unsplash.com/photo-1520333789090-1afc82db536a?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "CRM systems are instrumental in managing customer interactions and data throughout the customer lifecycle. By centralizing customer information and automating processes, businesses can improve productivity, enhance customer satisfaction, and drive growth.",
        how: "Our CRM solutions are tailored to meet your specific business needs, from custom development to seamless integration with existing systems. We focus on designing intuitive interfaces and implementing robust functionalities that empower your team to deliver exceptional customer experiences.",
        background: "With extensive experience in CRM implementation and optimization, our team has successfully supported businesses across diverse industries. From small startups to large enterprises, we have implemented scalable CRM solutions that align with business objectives and enhance operational efficiency.",
        metaTitle: "CRM Systems - Streamline Customer Management and Enhance Efficiency",
        metaDescription: "Implement CRM Systems to streamline customer management processes and improve relationships. Our solutions are designed to enhance business efficiency and customer satisfaction.",
        metaKeywords: "CRM systems, customer management, improve efficiency, CRM solutions",
    },
    {
        title: "Branding",
        description: "Build a strong brand identity with our comprehensive branding services. From logo design to brand strategy, we help you create a memorable brand that resonates with your target audience.",
        link: "/services/branding",
        slang: "Shape perceptions with captivating brands.",
        image: "https://images.unsplash.com/photo-1613909207039-6b173b755cc1?q=80&w=3347&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        why: "A strong brand identity is crucial for standing out in a competitive market and fostering customer loyalty. It encapsulates your company's values, mission, and unique offerings, creating a cohesive visual and emotional experience that resonates with your target audience. A well-defined brand identity not only distinguishes you from competitors but also builds credibility and trust among customers.",
        how: "At Zimapeak Marketing, we approach branding as a strategic endeavor that goes beyond visual aesthetics. We begin by conducting in-depth market research and competitor analysis to uncover insights that inform your brand strategy. From logo design to messaging, we collaborate closely with you to develop a distinctive brand identity that reflects your vision and resonates with your audience. Our process involves iterative feedback and refinement to ensure every element of your brand identity aligns with your business objectives and resonates with your target market.",
        background: "Our team comprises seasoned branding specialists with diverse backgrounds in design, marketing, and consumer psychology. We have successfully partnered with startups, small businesses, and established enterprises across various industries to build compelling brand identities. Whether you're launching a new brand or repositioning an existing one, our expertise in visual communication, brand strategy, and market research empowers us to create brands that leave a lasting impression.",
        metaTitle: "Branding Services - Build a Memorable Brand Identity",
        metaDescription: "Build a strong brand identity with our Branding services. From logo design to strategy development, we create brands that resonate with your audience and drive engagement.",
        metaKeywords: "branding services, brand identity, logo design, brand strategy",
    }
];

export default services;